import { useState, useEffect } from 'react';
import { Input, Button, Slider, Select, InputNumber, notification, Row, Col } from 'antd';
import { getSetting, updateBotSettings } from '../../services/chatbotServices';
import { getDefaultSetting, getBasePrompt, getContext, getLevel } from '../../services/adminServices';
import { getUserInfo } from '../../services/authServices';
import useTranslation from '../../locales/translations';

const { TextArea } = Input;

export default function Settings(props) {
    const translation = useTranslation().chatbot.settings;
    const botId = props.botId;

    const [name, setName] = useState(null);
    const [baseprompt, setBaseprompt] = useState(null);
    const [baseprompts, setBaseprompts] = useState([]);
    const [selectedBasePrompt, setSelectedBasePrompt] = useState('');
    const [temperature, setTemperature] = useState(null);
    const [visibility, setVisibility] = useState(null);
    const [domains, setDomains] = useState('');
    const [limit, setLimit] = useState(null);
    const [limittime, setLimittime] = useState(null);
    const [limitmsg, setLimitmsg] = useState(null);
    const [addContext, setAddContext] = useState(null);
    const [context, setContext] = useState('');
    const [contexts, setContexts] = useState([]);
    const [selectedContext, setSelectedContext] = useState('');
    const [rememberconv, setRememberconv] = useState(null);
    const [numberconv, setNumberconv] = useState(null);
    const [addlogs, setAddlogs] = useState(null);
    const [addsource, setAddsource] = useState(null);
    const [summarize, setSummarize] = useState(null);
    const [model, setModel] = useState(null);
    const [maxTokens, setMaxTokens] = useState(null);
    const [topP, setTopP] = useState(null);
    const [bestOf, setBestOf] = useState(null);
    const [frequency, setFrequency] = useState(null);
    const [presence, setPresence] = useState(null);
    const [language, setLanguage] = useState(null);
    const [tone, setTone] = useState(null);
    const [profession, setProfession] = useState(null);
    const [level, setLevel] = useState();

    const [defaultBaseprompt, setDefaultBaseprompt] = useState(null);
    const [defaultLimit, setDefaultLimit] = useState(null);
    const [defaultLimittime, setDefaultLimittime] = useState(null);
    const [defaultLimitmsg, setDefaultLimitmsg] = useState(null);

    const [isSaving, setIsSaving] = useState(false);

    const fetchLevel = async () => {
        try {
            const res = await getLevel();
            setLevel(res.data);
        } catch (err) {
            throw err;
        }
    };

    const fetchSetting = async () => {
        try {
            await getSetting(botId).then((res) => {
                if (res.data) {
                    const bot = res.data;
                    if (bot.name) {
                        setName(bot.name);
                    }
                    if (bot.baseprompt) {
                        setBaseprompt(bot.baseprompt);
                    }
                    if (bot.temperature) {
                        setTemperature(bot.temperature);
                    }
                    if (bot.visibility) {
                        setVisibility(bot.visibility);
                    }
                    if (bot.domains) {
                        setDomains(bot.domains);
                    }
                    if (bot.limit) {
                        setLimit(bot.limit);
                    }
                    if (bot.limittime) {
                        setLimittime(bot.limittime);
                    }
                    if (bot.limitmsg) {
                        setLimitmsg(bot.limitmsg);
                    }
                    if (bot.addContext) {
                        setAddContext(bot.addContext);
                    }
                    if (bot.context) {
                        setContext(bot.context);
                    }
                    if (bot.rememberconv) {
                        setRememberconv(bot.rememberconv);
                    }
                    if (bot.numberconv) {
                        setNumberconv(bot.numberconv);
                    }
                    if (bot.addlogs) {
                        setAddlogs(bot.addlogs);
                    }
                    if (bot.addsource) {
                        setAddsource(bot.addsource);
                    }
                    if (bot.summarize) {
                        setSummarize(bot.summarize);
                    }
                    if (bot.model) {
                        setModel(bot.model);
                    }
                    if (bot.maxTokens) {
                        setMaxTokens(bot.maxTokens);
                    }
                    if (bot.topP) {
                        setTopP(bot.topP);
                    }
                    if (bot.bestOf) {
                        setBestOf(bot.bestOf);
                    }
                    if (bot.frequency) {
                        setFrequency(bot.frequency);
                    }
                    if (bot.presence) {
                        setPresence(bot.presence);
                    }
                    if (bot.language) {
                        setLanguage(bot.language);
                    }
                    if (bot.tone) {
                        setTone(bot.tone);
                    }
                    if (bot.profession) {
                        setProfession(bot.profession);
                    }
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchDefaultSetting = async () => {
        try {
            await getDefaultSetting().then((res) => {
                if (res.data) {
                    const bot = res.data;
                    if (bot.baseprompt) {
                        setDefaultBaseprompt(bot.baseprompt);
                    }
                    if (bot.limit) {
                        setDefaultLimit(bot.limit);
                    }
                    if (bot.limittime) {
                        setDefaultLimittime(bot.limittime);
                    }
                    if (bot.limitmsg) {
                        setDefaultLimitmsg(bot.limitmsg);
                    }
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchBasePrompt = async () => {
        try {
            await getBasePrompt().then((res) => {
                setBaseprompts(res.data.map((val, index) => ({ key: index, value: val })));
            });
        } catch (err) {
            console.log(err);
        }
    };

    const fetchContext = async () => {
        try {
            await getContext().then((res) => {
                setContexts(res.data.map((val, index) => ({ key: index, value: val })));
            });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchSetting();
        fetchDefaultSetting();
        fetchBasePrompt();
        fetchContext();
        fetchLevel();
    }, [botId]);

    // Check model for old values
    useEffect(() => {
        if (!['gpt-4o-mini', 'gpt-4'].includes(model)) {
            setModel('gpt-4o-mini');
        }
    }, [model]);

    //bot settings

    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleBaseprompt = (e) => {
        setBaseprompt(e.target.value);
    };

    const handleContext = (e) => {
        setContext(e.target.value);
    };

    const handleTemperature = (e) => {
        setTemperature(e);
    };

    const handleVisibility = (e) => {
        setVisibility(e);
    };

    const handleDomains = (e) => {
        setDomains(e.target.value);
    };

    const handleLimit = (e) => {
        setLimit(e);
    };

    const handleLimittime = (e) => {
        setLimittime(e);
    };

    const handleLimitmsg = (e) => {
        setLimitmsg(e.target.value);
    };

    const handleAddContext = (e) => {
        setAddContext(e);
        if (e === 'no') setContext('');
    };

    const handleRememberconv = (e) => {
        setRememberconv(e);
    };

    const handleNumberconv = (e) => {
        setNumberconv(e);
    };

    const handleAddlogs = (e) => {
        setAddlogs(e);
    };

    const handleAddsource = (e) => {
        setAddsource(e);
    };

    const handleSummarize = (e) => {
        setSummarize(e);
    };

    const handleModel = (e) => {
        setModel(e);
    };

    const handleMaxTokens = (e) => {
        setMaxTokens(e);
    };

    const handleTopP = (e) => {
        setTopP(e);
    };

    const handleBestOf = (e) => {
        setBestOf(e);
    };

    const handleFrequency = (e) => {
        setFrequency(e);
    };

    const handlePresence = (e) => {
        setPresence(e);
    };

    const resetBaseprompt = () => {
        setBaseprompt(defaultBaseprompt);
    };

    const resetRateLimit = () => {
        setLimit(defaultLimit);
        setLimittime(defaultLimittime);
        setLimitmsg(defaultLimitmsg);
    };

    const handleSelectBasePrompt = (value) => {
        setSelectedBasePrompt(value);
        setBaseprompt(value);
    };

    const handleSelectContext = (value) => {
        setSelectedContext(value);
        setContext(value);
    };

    const handleSave = async (event) => {
        event.preventDefault();
        try {
            setIsSaving(true);
            await updateBotSettings(
                botId,
                name,
                baseprompt,
                temperature,
                visibility,
                domains,
                limit,
                limittime,
                limitmsg,
                addContext,
                context,
                rememberconv,
                numberconv,
                addlogs,
                addsource,
                summarize,
                model,
                maxTokens,
                topP,
                bestOf,
                frequency,
                presence,
                language,
                tone,
                profession,
            );
            notification.success({
                message: translation.notification.SUCCESS,
                placement: 'topRight',
            });
            setIsSaving(false);
            props.setIsSaved(true);
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
            setIsSaving(false);
        }
    };

    const handleLanguage = (e) => {
        setLanguage(e);
    };

    const handleTone = (e) => {
        setTone(e);
    };

    const handleProfession = (e) => {
        setProfession(e);
    };

    return (
        <>
            <div className="text-left margin-32">
                <div id="botSettings">
                    <div className="settings-subtitle">{translation.AI_ASSISTANT_ID}</div>
                    <div>{botId}</div>
                    <br />
                    <div className="settings-subtitle">{translation.NAME}</div>
                    <Input value={name} onChange={handleName} autoComplete="off" />
                    <br />
                    <br />
                    <Row className="settings-subtitle" gutter={[16, 16]}>
                        <Col>
                            <div className="settings-subtitle">{translation.language.TITLE}</div>
                            <Select
                                value={language}
                                onChange={handleLanguage}
                                style={{ width: 250 }}
                                options={[
                                    {
                                        value: 'french',
                                        label: 'Français',
                                    },
                                    {
                                        value: 'english',
                                        label: 'English',
                                    },
                                ]}
                            />
                        </Col>
                        <Col>
                            <div className="settings-subtitle">{translation.tone.TITLE}</div>
                            <Select
                                value={tone}
                                onChange={handleTone}
                                style={{ width: 250 }}
                                options={[
                                    {
                                        value: 'friendly',
                                        label: translation.tone.label.FRIENDLY,
                                    },
                                    {
                                        value: 'professional',
                                        label: translation.tone.label.PROFESSIONAL,
                                    },
                                    {
                                        value: 'sarcastic',
                                        label: translation.tone.label.SARCASTIC,
                                    },
                                    {
                                        value: 'humorous',
                                        label: translation.tone.label.HUMOROUS,
                                    },
                                    {
                                        value: 'cheerful',
                                        label: translation.tone.label.CHEERFUL,
                                    },
                                    {
                                        value: 'anecdotal',
                                        label: translation.tone.label.ANECDOTAL,
                                    },
                                ]}
                            />
                        </Col>
                        <Col>
                            <div className="settings-subtitle">{translation.profession.TITLE}</div>
                            <Select
                                value={profession}
                                onChange={handleProfession}
                                style={{ width: 250 }}
                                options={[
                                    {
                                        value: 'none',
                                        label: translation.profession.label.NONE,
                                    },
                                    {
                                        value: 'accountant',
                                        label: translation.profession.label.ACCOUNTANT,
                                    },
                                    {
                                        value: 'advertising specialist',
                                        label: translation.profession.label.ADVERTISING_SPECIALIST,
                                    },
                                    {
                                        value: 'architect',
                                        label: translation.profession.label.ARCHITECT,
                                    },
                                    {
                                        value: 'artist',
                                        label: translation.profession.label.ARTIST,
                                    },
                                    {
                                        value: 'blogger',
                                        label: translation.profession.label.BLOGGER,
                                    },
                                    {
                                        value: 'business analyst',
                                        label: translation.profession.label.BUSINESS_ANALYST,
                                    },
                                    {
                                        value: 'business owner',
                                        label: translation.profession.label.BUSINESS_OWNER,
                                    },
                                    {
                                        value: 'car expert',
                                        label: translation.profession.label.CAR_EXPERT,
                                    },
                                    {
                                        value: 'consultant',
                                        label: translation.profession.label.CONSULTANT,
                                    },
                                    {
                                        value: 'counselor',
                                        label: translation.profession.label.COUNSELOR,
                                    },
                                    {
                                        value: 'cryptocurrency trader',
                                        label: translation.profession.label.CRYPTOCURRENCY_TRADER,
                                    },
                                    {
                                        value: 'cryptocurrency expert',
                                        label: translation.profession.label.CRYPTOCURRENCY_EXPERT,
                                    },
                                    {
                                        value: 'customer support',
                                        label: translation.profession.label.CUSTOMER_SUPPORT,
                                    },
                                    {
                                        value: 'designer',
                                        label: translation.profession.label.DESIGNER,
                                    },
                                    {
                                        value: 'digital marketing agency',
                                        label: translation.profession.label.DIGITAL_MARKETING_AGENCY,
                                    },
                                    {
                                        value: 'editor',
                                        label: translation.profession.label.EDITOR,
                                    },
                                    {
                                        value: 'event planner',
                                        label: translation.profession.label.EVENT_PLANNER,
                                    },
                                    {
                                        value: 'freelancer',
                                        label: translation.profession.label.FREELANCER,
                                    },
                                    {
                                        value: 'insurance agent',
                                        label: translation.profession.label.INSURANCE_AGENT,
                                    },
                                    {
                                        value: 'insurance broker',
                                        label: translation.profession.label.INSURANCE_BROKER,
                                    },
                                    {
                                        value: 'interior designer',
                                        label: translation.profession.label.INTERIOR_DESIGNER,
                                    },
                                    {
                                        value: 'journalist',
                                        label: translation.profession.label.JOURNALIST,
                                    },
                                    {
                                        value: 'marketing agency',
                                        label: translation.profession.label.MARKETING_AGENCY,
                                    },
                                    {
                                        value: 'marketing expert',
                                        label: translation.profession.label.MARKETING_EXPERT,
                                    },
                                    {
                                        value: 'marketing specialist',
                                        label: translation.profession.label.MARKETING_SPECIALIST,
                                    },
                                    {
                                        value: 'photographer',
                                        label: translation.profession.label.PHOTOGRAPHER,
                                    },
                                    {
                                        value: 'programmer',
                                        label: translation.profession.label.PROGRAMMER,
                                    },
                                    {
                                        value: 'public relations agency',
                                        label: translation.profession.label.PUBLIC_RELATIONS_AGENCY,
                                    },
                                    {
                                        value: 'publisher',
                                        label: translation.profession.label.PUBLISHER,
                                    },
                                    {
                                        value: 'real estate agent',
                                        label: translation.profession.label.REAL_ESTATE_AGENT,
                                    },
                                    {
                                        value: 'recruiter',
                                        label: translation.profession.label.RECRUITER,
                                    },
                                    {
                                        value: 'reporter',
                                        label: translation.profession.label.REPORTER,
                                    },
                                    {
                                        value: 'salesperson',
                                        label: translation.profession.label.SALES_PERSON,
                                    },
                                    {
                                        value: 'sales representative',
                                        label: translation.profession.label.SALES_REPRESENTATIVE,
                                    },
                                    {
                                        value: 'seo agency',
                                        label: translation.profession.label.SEO_AGENCY,
                                    },
                                    {
                                        value: 'seo expert',
                                        label: translation.profession.label.SEO_EXPERT,
                                    },
                                    {
                                        value: 'social media agency',
                                        label: translation.profession.label.SOCIAL_MEDIA_AGENCY,
                                    },
                                    {
                                        value: 'student',
                                        label: translation.profession.label.STUDENT,
                                    },
                                    {
                                        value: 'teacher',
                                        label: translation.profession.label.TEACHER,
                                    },
                                    {
                                        value: 'technical support',
                                        label: translation.profession.label.TECHNICAL_SUPPORT,
                                    },
                                    {
                                        value: 'trainer',
                                        label: translation.profession.label.TRAINER,
                                    },
                                    {
                                        value: 'travel agency',
                                        label: translation.profession.label.TRAVEL_AGENCY,
                                    },
                                    {
                                        value: 'videographer',
                                        label: translation.profession.label.VIDEOGRAPHER,
                                    },
                                    {
                                        value: 'web design agency',
                                        label: translation.profession.label.WEB_DESIGN_AGENCY,
                                    },
                                    {
                                        value: 'web design expert',
                                        label: translation.profession.label.WEB_DESIGN_EXPERT,
                                    },
                                    {
                                        value: 'web development agency',
                                        label: translation.profession.label.WEB_DEVELOPMENT_AGENCY,
                                    },
                                    {
                                        value: 'web development expert',
                                        label: translation.profession.label.WEB_DEVELOPMENT_EXPERT,
                                    },
                                    {
                                        value: 'web designer',
                                        label: translation.profession.label.WEB_DESIGNER,
                                    },
                                    {
                                        value: 'web developer',
                                        label: translation.profession.label.WEB_DEVELOPER,
                                    },
                                    {
                                        value: 'writer',
                                        label: translation.profession.label.WRITER,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <br />
                    <div className="display-flex">
                        <div className="settings-subtitle">{translation.BASE_PROMPT}</div>
                        <Button size="small" onClick={resetBaseprompt}>
                            {translation.RESET}
                        </Button>
                    </div>
                    <TextArea rows={5} value={baseprompt} onChange={handleBaseprompt} />
                    <br />
                    <br />
                    <div className="settings-subtitle">{translation.SELECT_BASE_PROMPT}</div>
                    <Select
                        className="width-100"
                        options={baseprompts}
                        value={selectedBasePrompt}
                        onChange={handleSelectBasePrompt}
                    />
                    <br />
                    <br />
                    <div className="settings-subtitle">{translation.TEMPERATURE}</div>
                    <Slider
                        min={0}
                        max={1}
                        step={0.1}
                        marks={{ 0: translation.RESERVED, 1: translation.CREATIVE }}
                        value={temperature}
                        onChange={handleTemperature}
                    />
                    <br />
                    <div className="settings-subtitle">{translation.visibility.TITLE}</div>
                    <Select
                        className="width-100"
                        value={visibility}
                        options={[
                            {
                                value: 'private',
                                label: translation.visibility.PRIVATE,
                            },
                            {
                                value: 'private-embed',
                                label: translation.visibility.PRIVATE_EMBED,
                            },
                            {
                                value: 'public',
                                label: translation.visibility.PUBLIC,
                            },
                        ]}
                        onChange={handleVisibility}
                    />
                    <div className="settings-content">
                        <p>{translation.visibility.PRIVATE_DES}</p>
                        <p>{translation.visibility.PRIVATE_EMBED_DES}</p>
                        <p>{translation.visibility.PUBLIC_DES}</p>
                        <p>{translation.visibility.LAST_DES}</p>
                    </div>
                    <br />
                    <div className="settings-subtitle">{translation.domains.TITLE}</div>
                    <TextArea rows={2} placeholder="www.example.com" value={domains} onChange={handleDomains} />
                    <div className="settings-content">
                        <p>{translation.domains.DES_FIRST}</p>
                        <p>{translation.domains.DES_LAST}</p>
                    </div>
                    <br />
                    <div className="display-flex">
                        <div className="settings-subtitle">{translation.limit.TITLE}</div>
                        <Button size="small" onClick={resetRateLimit}>
                            {translation.RESET}
                        </Button>
                    </div>
                    <div className="settings-content">
                        <p>{translation.limit.DESCRIPTION}</p>
                        <div style={{ color: 'black' }}>
                            {translation.limit.DES_FISRT}
                            <InputNumber value={limit} onChange={handleLimit} autoComplete="off" />
                            {translation.limit.DES_MIDDLE}
                            <InputNumber value={limittime} onChange={handleLimittime} autoComplete="off" />
                            {translation.limit.DES_LAST}
                            <br />
                            <br />
                            {translation.limit.LIMIT_MESSAGE}
                            <Input value={limitmsg} onChange={handleLimitmsg} autoComplete="off" />
                        </div>
                    </div>
                    <div className="settings-subtitle">{translation.ADD_CONTEXT}</div>
                    <Select
                        className="width-100"
                        value={addContext}
                        options={[
                            {
                                value: 'no',
                                label: translation.NO,
                            },
                            {
                                value: 'yes',
                                label: translation.YES,
                            },
                        ]}
                        onChange={handleAddContext}
                    />
                    <br />
                    <br />
                    {addContext === 'yes' ? (
                        <>
                            <div className="display-flex">
                                <div className="settings-subtitle">{translation.CONTEXT}</div>
                            </div>
                            <TextArea rows={5} value={context} onChange={handleContext} />
                            <br />
                            <br />
                            <div className="settings-subtitle">{translation.SELECT_CONTEXT}</div>
                            <Select
                                className="width-100"
                                options={contexts}
                                value={selectedContext}
                                onChange={handleSelectContext}
                            />
                            <br />
                            <br />
                        </>
                    ) : null}
                    <div className="settings-subtitle">{translation.REMEMBER_CONVERSATION}</div>
                    <Select
                        className="width-100"
                        value={rememberconv}
                        options={[
                            {
                                value: 'no',
                                label: translation.NO,
                            },
                            {
                                value: 'yes',
                                label: translation.YES,
                            },
                        ]}
                        onChange={handleRememberconv}
                    />
                    <br />
                    <br />
                    <div className="settings-subtitle">{translation.CONVERSATION_NUMBER}</div>
                    <InputNumber value={numberconv} onChange={handleNumberconv} autoComplete="off" />
                    <br />
                    <br />
                    <div className="settings-subtitle">{translation.ADD_LOGS}</div>
                    <Select
                        className="width-100"
                        value={addlogs}
                        options={[
                            {
                                value: 'no',
                                label: translation.NO,
                            },
                            {
                                value: 'yes',
                                label: translation.YES,
                            },
                        ]}
                        onChange={handleAddlogs}
                    />
                    <br />
                    <br />
                    <div className="settings-subtitle">{translation.ADD_SOURCE}</div>
                    <Select
                        className="width-100"
                        value={addsource}
                        options={[
                            {
                                value: 'no',
                                label: translation.NO,
                            },
                            {
                                value: 'yes',
                                label: translation.YES,
                            },
                        ]}
                        onChange={handleAddsource}
                    />
                    <br />
                    <br />
                    <div className="settings-subtitle">{translation.SUMMARIZE}</div>
                    <Select
                        className="width-100"
                        value={summarize}
                        options={[
                            {
                                value: 'no',
                                label: translation.NO,
                            },
                            {
                                value: 'yes',
                                label: translation.YES,
                            },
                        ]}
                        onChange={handleSummarize}
                    />
                    <br />
                    <br />
                    <div className="settings-subtitle">{translation.MODEL}</div>
                    <Select
                        className="width-100"
                        value={model}
                        options={[
                            {
                                value: 'gpt-4o-mini',
                                label: 'GPT-4o-mini',
                            },
                            {
                                value: 'gpt-4',
                                label: 'GPT-4',
                                disabled: level && level.gpt4 === 'no' ? true : false,
                            },
                        ]}
                        onChange={handleModel}
                    />
                    <br />
                    <br />
                    <div className="settings-subtitle">{translation.OTHRE_PARAMETERS}</div>
                    <Row className="settings-subtitle" gutter={[16, 16]}>
                        <Col>
                            <InputNumber
                                value={maxTokens}
                                onChange={handleMaxTokens}
                                addonBefore={translation.MAX_TOKENS}
                                style={{ width: 250 }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col>
                            <InputNumber
                                value={bestOf}
                                onChange={handleBestOf}
                                addonBefore={translation.BEST_OF}
                                style={{ width: 250 }}
                                autoComplete="off"
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row className="settings-subtitle" gutter={[16, 16]}>
                        <Col>
                            <InputNumber
                                value={topP}
                                onChange={handleTopP}
                                addonBefore={translation.TOP_P}
                                style={{ width: 250 }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col>
                            <InputNumber
                                value={frequency}
                                onChange={handleFrequency}
                                addonBefore={translation.FREQUENCY_PENALTY}
                                style={{ width: 250 }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col>
                            <InputNumber
                                value={presence}
                                onChange={handlePresence}
                                addonBefore={translation.PRESENCE_PENALTY}
                                style={{ width: 250 }}
                                autoComplete="off"
                            />
                        </Col>
                    </Row>
                </div>

                <Button size="large" type="primary" className="float-right" onClick={handleSave} loading={isSaving}>
                    {isSaving ? translation.SAVE_LOADING : translation.SAVE_CHANGES}
                </Button>
            </div>
        </>
    );
}
