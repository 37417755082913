import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Tabs, Modal, Input, notification } from 'antd';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import bcrypt from 'bcryptjs';
import base64url from 'base64url';
import ManageSources from './managesource';
import Conversations from './conversations';
import MainWindows from './mainwindows';
import Settings from './settings';
import Interface from './interface';
import { getChatbot, deleteChatbot, addDomains, getSetting } from '../../services/chatbotServices';
import { getLevel } from '../../services/adminServices';
import { getUserInfo } from '../../services/authServices';
import useTranslation from '../../locales/translations';
const { confirm } = Modal;
const { TextArea } = Input;

export default function Chatbot() {
    const translation = useTranslation().chatbot;
    const loadingTexts = useTranslation().loadingtext;
    const firstLoadingTexts = useTranslation().loadingtext.first;
    const firstLoadingText = firstLoadingTexts[Math.floor(Math.random() * firstLoadingTexts.length)];
    const navigate = useNavigate();
    const salt = bcrypt.genSaltSync(12);
    const { botId } = useParams();
    const [chatbotName, setChatbotName] = useState('');
    const [isSaved, setIsSaved] = useState(false);
    const [isGetAnswer, setIsGetAnswer] = useState(false);
    const [domains, setDomains] = useState('');
    const [currentDomains, setCurrentDomains] = useState('');
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [training, setTraining] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [transcribing, setTranscribing] = useState(false);
    const url = window.location.origin;
    const [scriptCode, setScriptCode] = useState(`<script
  async=""
  src="${url}/widget.js"
  data-chat-app-id="AIBot"
  data-chat-app-key="${botId}"
  data-chat-app-hash=""
></script>`);
    const [htmlCode, setHtmlCode] = useState(`<iframe
  src="${url}/chatbotwidget/${botId}"
  width="100%"
  style="height: 100%; min-height: 700px"
  frameborder="0"
></iframe>`);

    const fetchBot = async () => {
        try {
            await getChatbot(botId).then((res) => {
                if (res.data) {
                    const bot = res.data;
                    if (bot.name.length) {
                        setChatbotName(bot.name);
                    }
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchLevel = async () => {
        try {
            const res = await getLevel();
            const branding = res.data.branding;
            if (branding) {
                const hash = bcrypt.hashSync(botId, salt);
                const hashValue = base64url.fromBase64(hash);
                setScriptCode(`<script
  async=""
  src="${url}/widget.js"
  data-chat-app-id="AIBot"
  data-chat-app-key="${botId}"
  data-chat-app-hash="${hashValue}"
></script>`);
                setHtmlCode(`<iframe
  src="${url}/chatbotwidget/${botId}/${hashValue}?"
  width="100%"
  style="height: 100%; min-height: 700px"
  frameborder="0"
></iframe>`);
            }
        } catch (err) {
            throw err;
        }
    };

    const fetchDomains = async () => {
        try {
            const res = await getSetting(botId);
            setCurrentDomains(res.data.domains);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        fetchBot();
        fetchLevel();
        fetchDomains();
    }, [botId]);

    const items = [
        {
            key: 'chatbot_main',
            label: translation.mainwindows.TITLE,
            children: (
                <>
                    <MainWindows
                        botId={botId}
                        isSaved={isSaved}
                        setIsSaved={setIsSaved}
                        setIsGetAnswer={setIsGetAnswer}
                    />
                </>
            ),
        },
        {
            key: 'settings',
            label: translation.settings.TITLE,
            children: (
                <>
                    <Settings botId={botId} setIsSaved={setIsSaved} />
                </>
            ),
        },
        {
            key: 'interface',
            label: translation.inferface.TITLE,
            children: (
                <>
                    <Interface botId={botId} setIsSaved={setIsSaved} />
                </>
            ),
        },
        {
            key: 'conversations',
            label: translation.conversation.TITLE,
            children: (
                <>
                    <Conversations botId={botId} isGetAnswer={isGetAnswer} setIsGetAnswer={setIsGetAnswer} />
                </>
            ),
        },
        {
            key: 'manageSources',
            label: translation.managesource.TITLE,
            children: (
                <>
                    <ManageSources
                        botId={botId}
                        setFetching={setFetching}
                        setLoading={setLoading}
                        setTraining={setTraining}
                        setUploading={setUploading}
                        setTranscribing={setTranscribing}
                    />
                </>
            ),
        },
        {
            key: 'embedOnWebsite',
            label: translation.embed.TITLE,
            children: <></>,
        },
        {
            key: 'delete',
            label: translation.delete.TITLE,
            children: <></>,
        },
    ];

    const [confirmEmbed, setConfirmEmbed] = useState(false);
    const [embed, setEmbed] = useState(false);

    const handleAddDomains = async () => {
        if (domains.length) {
            try {
                await addDomains(botId, domains);
                await fetchDomains(botId);
            } catch (err) {
                notification.error({
                    message: translation.delete.notification.ERROR,
                    placement: 'topRight',
                });
            }
        }
        setConfirmEmbed(false);
        setEmbed(true);
    };

    const handleDomains = (e) => {
        setDomains(e.target.value);
    };

    const handleDelete = (key) => {
        if (key === 'embedOnWebsite') {
            if (currentDomains && currentDomains.length) {
                setEmbed(true);
            } else {
                setConfirmEmbed(true);
            }
        }
        if (key === 'delete') {
            confirm({
                title: translation.delete.TITLE,
                content: translation.delete.CONTENT,
                okText: translation.delete.OKTEXT,
                okType: 'danger',
                cancelText: translation.delete.CANCELTEXT,
                async onOk() {
                    try {
                        await deleteChatbot(botId);
                        notification.success({
                            message: translation.delete.notification.SUCCESS,
                            placement: 'topRight',
                        });
                        navigate('/mychatbots');
                    } catch (err) {
                        notification.error({
                            message: translation.delete.notification.ERROR,
                            placement: 'topRight',
                        });
                    }
                },
                onCancel() {},
            });
        }
    };
    return (
        <>
            <div
                className="main-loading"
                style={{
                    display: uploading || fetching || loading || transcribing ? 'block' : 'none',
                }}>
                <div
                    className="container"
                    style={{
                        display: uploading || fetching || loading || transcribing ? 'block' : 'none',
                    }}>
                    <div className="main-lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="cover" style={{ display: training ? 'block' : 'none' }}>
                <div
                    className="loading-text-1"
                    style={{
                        display: training ? 'block' : 'none',
                    }}>
                    {firstLoadingText}
                </div>
                <div className="container" style={{ display: training ? 'block' : 'none' }}>
                    <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div
                    className="loading-text-2"
                    style={{
                        display: training ? 'block' : 'none',
                    }}>
                    <i>{loadingTexts.second}</i>
                </div>
            </div>

            <div className="main-layout card-layout">
                <h1 style={{ fontSize: 32 }}>{chatbotName}</h1>
                <Tabs defaultActiveKey="chatbot_main" items={items} size="large" onTabClick={handleDelete} />
                <Modal
                    open={confirmEmbed}
                    footer={null}
                    closable={false}
                    title={translation.embed.firstmodal.TITLE}
                    onCancel={() => {
                        setConfirmEmbed(false);
                    }}>
                    <p style={{ wordBreak: 'break-all' }}>{translation.embed.firstmodal.DESCRIPTION}</p>
                    <TextArea placeholder="www.example.com" value={domains} onChange={handleDomains} />
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button type="primary" onClick={handleAddDomains}>
                            {translation.embed.firstmodal.ADD}
                        </Button>
                    </div>
                </Modal>
                <Modal
                    open={embed}
                    footer={null}
                    closable={false}
                    title={translation.embed.secondmodal.TITLE}
                    maskClosable={true}
                    onCancel={() => {
                        setIsSaved(true);
                        setEmbed(false);
                    }}>
                    <p>{translation.embed.secondmodal.FIRST_DES}</p>
                    <SyntaxHighlighter language="javascript" style={tomorrowNight}>
                        {htmlCode}
                    </SyntaxHighlighter>
                    <p>{translation.embed.secondmodal.SECOND_DES}</p>
                    <SyntaxHighlighter language="javascript" style={tomorrowNight}>
                        {scriptCode}
                    </SyntaxHighlighter>
                </Modal>
            </div>
        </>
    );
}
