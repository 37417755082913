import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Tabs, Input, Space, Select, notification } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { uploads, crawl, loadSiteMap, transcribe, createChatbot } from '../../services/chatbotServices';
import { getLevel } from '../../services/adminServices';
import { getUserInfo } from '../../services/authServices';
import { Document, Page, pdfjs } from 'react-pdf';
import useTranslation from '../../locales/translations';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { TextArea } = Input;

export default function CreateNewBot() {
    const translation = useTranslation().dashboard.create;
    const loadingTexts = useTranslation().loadingtext;
    const firstLoadingTexts = useTranslation().loadingtext.first;
    const firstLoadingText = firstLoadingTexts[Math.floor(Math.random() * firstLoadingTexts.length)];
    const navigate = useNavigate();

    const [url, setUrl] = useState('');
    const [urls, setUrls] = useState('');
    const [level, setLevel] = useState();
    const [linkList, setLinkList] = useState([]);
    const [previewFile, setPreviewFile] = useState([]);
    const [originFiles, setOriginFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [botName, setBotName] = useState('');
    const [botContent, setBotContent] = useState('');
    const [fileNum, setFileNum] = useState(0);
    const [fileChaNum, setFileChaNum] = useState(0);
    const [textChaNum, setTextChaNum] = useState(0);
    const [linkChaNum, setLinkChaNum] = useState(0);
    const [videoChaNum, setVideoChaNum] = useState(0);
    const [totalChaNum, setTotalChaNum] = useState(0);
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [videoList, setVideoList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [transcribing, setTranscribing] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const user = getUserInfo();
    const filteredUsersOptions = []; //TODO Fix ALLOCATED_USERS propositions

    const fetchLevel = async () => {
        try {
            const res = await getLevel();
            setLevel(res.data);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        fetchLevel();
    }, []);

    useEffect(() => {
        const totalCharacters = files.reduce((total, file) => {
            return total + file.characters;
        }, 0);
        setFileChaNum(totalCharacters);
        setFileNum(files.length);
        setTotalChaNum(totalCharacters + textChaNum + linkChaNum + videoChaNum);
    }, [files]);

    useEffect(() => {
        const totalCharacters = linkList.reduce((total, link) => {
            return total + link.characters || 0;
        }, 0);
        setLinkChaNum(totalCharacters);
        setTotalChaNum(fileChaNum + textChaNum + totalCharacters + videoChaNum);
    }, [linkList]);

    useEffect(() => {
        const totalCharacters = videoList.reduce((total, video) => {
            return total + video.characters || 0;
        }, 0);
        setVideoChaNum(totalCharacters);
        setTotalChaNum(fileChaNum + textChaNum + linkChaNum + totalCharacters);
    }, [videoList]);

    useEffect(() => {
        const preview = [];
        if (originFiles.length) {
            for (const file of originFiles) {
                if (!preview.length && file.type === 'application/pdf') {
                    preview.push(file);
                    setPreviewFile(preview);
                }
            }
        }
        if (preview.length) setUrl(URL.createObjectURL(preview[0]));
    }, [originFiles]);

    const onChange = async () => {
        const fileElement = document.getElementById('fileSelect');
        if (fileElement.files.length) {
            setUploading(true);
            let newFiles = [...fileElement.files];
            if (files.length) {
                newFiles = newFiles.filter((newFile) => {
                    return !files.find((file) => file.source === newFile.name);
                });
            }
            if (newFiles.length) {
                setOriginFiles([...originFiles, ...newFiles]);
                const formData = new FormData();
                for (const key of Object.keys(newFiles)) {
                    formData.append('files', newFiles[key]);
                }
                try {
                    let fileSource = await uploads(formData);
                    fileSource.forEach((file) => {
                        file.characters = file.content.reduce((total, data) => {
                            return total + (data.pageContent?.length || 0);
                        }, 0);
                    });
                    setFiles([...files, ...fileSource]);
                } catch (err) {
                    notification.error({
                        message: err.message,
                        placement: 'topRight',
                    });
                }
            }
            setUploading(false);
        }
    };

    const handleNameChange = (e) => {
        setBotName(e.target.value);
    };

    const handleTextChange = (e) => {
        setBotContent(e.target.value);
        setTextChaNum(e.target.value.length);
        setTotalChaNum(fileChaNum + e.target.value.length + linkChaNum + videoChaNum);
    };

    const handleClick = () => {
        document.getElementById('fileSelect').click();
    };

    const handleFetch = async (event) => {
        event.preventDefault();
        setFetching(true);
        try {
            const url = document.getElementById('url').value;
            let linkSource = await crawl(url);
            if (linkSource.length) {
                linkSource = linkSource.filter((link) => {
                    return !linkList.some((item) => item.source === link.source);
                });
            }
            if (linkSource.length) {
                linkSource.forEach((link, index) => {
                    let current;
                    if (linkList.length) current = linkList[linkList.length - 1].id + 1;
                    else current = 1;
                    link.id = index + current;
                    link.characters = link.content.reduce((total, data) => {
                        return total + (data.pageContent?.length || 0);
                    }, 0);
                });
            }
            setLinkList(linkList.concat(linkSource));
        } catch (err) {
            console.log('error');
        }
        setFetching(false);
    };

    const deleteOneFile = (index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
        setOriginFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };

    const deleteAll = () => {
        setLinkList([]);
    };

    const deleteAllVideos = () => {
        setVideoList([]);
    };

    const deleteOneLink = (id) => {
        const updatedList = linkList.filter((link) => link.id !== id);
        setLinkList(updatedList);
    };

    const deleteOneVideo = (id) => {
        const updatedList = videoList.filter((video) => video.id !== id);
        setVideoList(updatedList);
    };

    const addLink = () => {
        const newLink =
            linkList.length > 0
                ? {
                      id: linkList[linkList.length - 1].id + 1,
                      source: '',
                      content: [],
                      characters: 0,
                  }
                : { id: 0, source: '', content: [], characters: 0 };
        setLinkList(linkList.concat(newLink));
    };

    const addVideo = async () => {
        if (urls) {
            setTranscribing(true);
            const newUrls = urls.split('\n');
            let videoSource = await transcribe(newUrls);
            if (videoSource.length) {
                videoSource = videoSource.filter((video) => {
                    return !videoList.some((item) => video.source === item.source);
                });
            }
            if (videoSource.length) {
                videoSource.forEach((video, index) => {
                    let current;
                    if (videoList.length) current = videoList[videoList.length - 1].id + 1;
                    else current = 1;
                    video.id = index + current;
                    video.characters = video.content.reduce((total, data) => {
                        return total + (data.pageContent?.length || 0);
                    }, 0);
                });
            }
            setVideoList(videoList.concat(videoSource));
            setUrls('');
            setTranscribing(false);
        }
    };

    const handleCreate = async (event) => {
        event.preventDefault();
        setCreating(true);
        if (document.getElementById('chatbotName').value === '') {
            notification.error({
                message: translation.notification.NAME_ERROR,
                placement: 'topRight',
            });
        } else if (user.role === 'user' && (totalChaNum > level.numberOfCharacters || 0)) {
            notification.error({
                message: `${translation.notification.LIMIT_ERROR_FIRST}${level.numberOfCharacters || 0}${
                    translation.notification.LIMIT_ERROR_SECOND
                }`,
                placement: 'topRight',
            });
        } else if (files.length || botContent.length || linkList.length || videoList.length) {
            try {
                let chatbotFiles = [];
                let chatbotContent = [];
                let chatbotLinks = [];
                let chatbotVideos = [];
                if (files.length) {
                    chatbotFiles = files;
                }
                if (botContent.length) {
                    chatbotContent = [
                        {
                            characters: textChaNum,
                            content: [{ pageContent: botContent }],
                            source: 'Plain Text',
                        },
                    ];
                }
                if (linkList.length) chatbotLinks = linkList;
                if (videoList.length) chatbotVideos = videoList;
                const data = await createChatbot(
                    botName,
                    chatbotFiles,
                    chatbotContent,
                    chatbotLinks,
                    chatbotVideos,
                    selectedItems,
                );
                notification.success({
                    message: translation.notification.CREATE_SUCCESS,
                    placement: 'topRight',
                });
                setCreating(false);
                navigate(`/chatbot/${data.botId}`);
            } catch (err) {
                notification.error({
                    message: err.response?.data?.message || err.message,
                    placement: 'topRight',
                });
            }
        } else {
            notification.error({
                message: translation.notification.SOURCE_ERROR,
                placement: 'topRight',
            });
        }
        setCreating(false);
    };

    const handleLoadSiteMap = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const url = document.getElementById('sitemap').value;
            let linkSource = await loadSiteMap(url);
            if (linkSource.length) {
                linkSource = linkSource.filter((link) => {
                    return !linkList.some((item) => item.source === link.source);
                });
            }
            if (linkSource.length) {
                linkSource.forEach((link, index) => {
                    let current;
                    if (linkList.length) current = linkList[linkList.length - 1].id + 1;
                    else current = 1;
                    link.id = index + current;
                    link.characters = link.content.reduce((total, data) => {
                        return total + (data.pageContent?.length || 0);
                    }, 0);
                });
            }
            setLinkList(linkList.concat(linkSource));
        } catch (err) {}
        setLoading(false);
    };

    const handleVideoUrls = (e) => {
        setUrls(e.target.value);
    };

    const items = level && [
        (user.role === 'admin' || level.enableFiles) && {
            key: 'file',
            label: translation.files.TITLE,
            children: (
                <>
                    <Space direction="vertical" style={{ width: '70%' }}>
                        <div className="pdf-view" style={{ height: 240, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {previewFile.length ? (
                                <Document file={url} style={{ margin: 'auto' }}>
                                    <Page
                                        pageNumber={1}
                                        width={180}
                                        height={240}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={false}
                                    />
                                </Document>
                            ) : (
                                <img
                                    alt="preview"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    src="unknown.png"
                                />
                            )}
                        </div>
                        <span className="upload-txt">{translation.files.UPLOAD_FILES}</span>
                        <span
                            id="fileCharacters"
                            className="font-weight-normal history-title normal-component"
                            style={{ height: 'auto' }}>
                            {fileChaNum
                                ? `${fileChaNum}${translation.files.FILE_CHARS}`
                                : `${translation.files.UPLOAD_DESCRIPTION}`}
                        </span>
                        <Input
                            type="file"
                            id="fileSelect"
                            accept=".pdf,.doc,.docx,.txt,.csv,.xls,.xlsx"
                            multiple
                            onChange={onChange}
                            style={{ display: 'none' }}
                            disabled={creating}
                            autoComplete="off"
                        />
                        <Button
                            className="normal-component"
                            icon={<UploadOutlined />}
                            onClick={handleClick}
                            style={{ height: 'auto' }}
                            disabled={uploading || creating}
                            loading={uploading}>
                            {translation.files.CLICK_TO_UPLOAD}
                        </Button>
                        <div id="fileList" className="font-weight-normal width-100">
                            {!files.length ? (
                                <>{translation.files.NO_FILE_SELECT}</>
                            ) : (
                                <div className="sbtn-widget">
                                    {Object.values(files).map((file, index) => (
                                        <div key={index} className="input-button">
                                            <Input
                                                value={file.source + ` (${file.characters}${translation.FILE_CHARS})`}
                                                size="small"
                                                style={{ margin: '4px 0' }}
                                                disabled={creating}
                                                autoComplete="off"
                                            />
                                            <Button
                                                danger
                                                size="small"
                                                icon={<DeleteOutlined />}
                                                onClick={() => deleteOneFile(index)}
                                                type="text"
                                                disabled={creating}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </Space>
                </>
            ),
        },
        (user.role === 'admin' || level.enableText) && {
            key: 'text',
            label: translation.text.TITLE,
            children: (
                <>
                    <Space direction="vertical" className="width-100">
                        <TextArea
                            id="textContent"
                            rows={10}
                            placeholder={translation.text.PLACEHOLDER}
                            value={botContent}
                            onChange={handleTextChange}
                            disabled={creating}
                        />
                        <span id="textCharacters" className="font-weight-normal history-time">
                            {textChaNum ? `${textChaNum}${translation.text.TEXT_CHARS}` : ''}
                        </span>
                    </Space>
                </>
            ),
        },
        (user.role === 'admin' || level.enableWebsite) && {
            key: 'website',
            label: translation.website.TITLE,
            children: (
                <>
                    <Space direction="vertical" style={{ width: '100%', textAlign: 'left' }}>
                        <span>{translation.website.CRAWL}</span>
                        <div className="input-button">
                            <Input
                                id="url"
                                placeholder="https://www.example.com"
                                size="large"
                                disabled={creating || fetching}
                                autoComplete="off"
                            />
                            <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: 8 }}
                                onClick={handleFetch}
                                loading={fetching}
                                disabled={creating}>
                                {fetching ? translation.website.FETCH_LOADING : translation.website.FETCH_LINKS}
                            </Button>
                        </div>
                        <span style={{ fontWeight: 'normal' }}>{translation.website.CRAWL_DESCRIPTION}</span>
                        <Divider plain>{translation.website.OR}</Divider>
                        <span>{translation.website.SUBMIT_SITEMAP}</span>
                        <div className="input-button">
                            <Input
                                placeholder="https://www.example.com/sitemap.xml"
                                size="large"
                                id="sitemap"
                                disabled={creating || loading}
                                autoComplete="off"
                            />
                            <Button
                                type="primary"
                                size="large"
                                style={{ marginLeft: 8 }}
                                onClick={handleLoadSiteMap}
                                loading={loading}
                                disabled={creating}>
                                {loading ? translation.website.PARSE_LOADING : translation.website.PARSE_SITEMAP}
                            </Button>
                        </div>
                        <div className="margin-top-24">
                            <Space direction="vertical" className="width-100">
                                <span>{translation.website.INCLUDED_LINKS}</span>
                                <div className="sbtn-widget">
                                    {linkList.length !== 0 ? (
                                        <Button danger type="text" onClick={deleteAll} disabled={creating}>
                                            {translation.website.DELETE_ALL}
                                        </Button>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                                {linkList.map((link) => (
                                    <div key={link.id} className="input-button">
                                        {link.content.length ? (
                                            <Input
                                                placeholder="https://www.example.com/privacy-policy"
                                                value={
                                                    link.source + (link.characters ? ` (${link.characters} chars)` : '')
                                                }
                                                disabled={creating}
                                                autoComplete="off"
                                            />
                                        ) : (
                                            <Input
                                                placeholder="https://www.example.com/privacy-policy"
                                                defaultValue={link.source}
                                                onChange={(event) =>
                                                    setLinkList(
                                                        linkList.map((item) =>
                                                            item.id === link.id
                                                                ? { ...item, source: event.target.value }
                                                                : item,
                                                        ),
                                                    )
                                                }
                                                disabled={creating}
                                                autoComplete="off"
                                            />
                                        )}

                                        <Button
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => deleteOneLink(link.id)}
                                            type="text"
                                            disabled={creating}
                                        />
                                    </div>
                                ))}
                                <div className="sbtn-widget">
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={addLink}
                                        style={{ marginLeft: 8, border: 'none' }}
                                        disabled={creating}>
                                        {translation.website.ADD}
                                    </Button>
                                </div>
                            </Space>
                        </div>
                    </Space>
                </>
            ),
        },
        (user.role === 'admin' || level.enableVideos) && {
            key: 'video',
            label: translation.videos.TITLE,
            children: (
                <>
                    <Space direction="vertical" style={{ width: '100%', textAlign: 'left' }}>
                        <div className="margin-top-24">
                            <Space direction="vertical" className="width-100">
                                <span>{translation.videos.INCLUDED_LINKS}</span>
                                <TextArea
                                    rows={5}
                                    placeholder={translation.videos.PLACEHOLDER}
                                    value={urls}
                                    onChange={handleVideoUrls}
                                />
                                <div className="sbtn-widget">
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={addVideo}
                                        style={{ marginLeft: 8, border: 'none' }}
                                        disabled={creating}
                                        loading={transcribing}>
                                        {translation.videos.ADD}
                                    </Button>
                                </div>
                                <div className="sbtn-widget">
                                    {videoList.length !== 0 ? (
                                        <Button danger type="text" onClick={deleteAllVideos} disabled={creating}>
                                            {translation.videos.DELETE_ALL}
                                        </Button>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                                {videoList.map((video) => (
                                    <div key={video.id} className="input-button">
                                        <Input
                                            placeholder="https://www.youtube.com/watch?v=gddNRAxnJhE"
                                            value={
                                                video.source + (video.characters ? ` (${video.characters} chars)` : '')
                                            }
                                            disabled={creating}
                                            autoComplete="off"
                                        />
                                        <Button
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => deleteOneVideo(video.id)}
                                            type="text"
                                            disabled={creating}
                                        />
                                    </div>
                                ))}
                            </Space>
                        </div>
                    </Space>
                </>
            ),
        },
    ];

    return (
        <>
            <div
                className="main-loading"
                style={{
                    display: uploading || fetching || loading || transcribing ? 'block' : 'none',
                }}>
                <div
                    className="container"
                    style={{
                        display: uploading || fetching || loading || transcribing ? 'block' : 'none',
                    }}>
                    <div className="main-lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="cover" style={{ display: creating ? 'block' : 'none' }}>
                <div
                    className="loading-text-1"
                    style={{
                        display: creating ? 'block' : 'none',
                    }}>
                    {firstLoadingText}
                </div>
                <div className="container" style={{ display: creating ? 'block' : 'none' }}>
                    <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div
                    className="loading-text-2"
                    style={{
                        display: creating ? 'block' : 'none',
                    }}>
                    <i>{loadingTexts.second}</i>
                </div>
            </div>
            <div className="main-layout card-layout" style={{ marginTop: 20 }}>
                <h1 style={{ fontSize: 32 }}>{translation.TITLE}</h1>
                <Tabs defaultActiveKey="file" centered items={items} size="large" />
                <br />
                <div className="create-chatbot-box">
                    <p>
                        {translation.INCLUDED_SOURCES}
                        {` `}
                        {totalChaNum}
                        {translation.files.FILE_CHARS}
                        {user.role === 'user' && ` / ${level && level.numberOfCharacters} limits`}
                    </p>
                    <p>
                        {fileNum ? `${fileNum}${translation.FILES} (${fileChaNum}${translation.FILE_CHARS})` : ''}
                        {fileNum && textChaNum ? ' | ' : ''}
                        {textChaNum ? `${textChaNum}${translation.TEXT_CHARS}` : ''}
                        {(fileNum || textChaNum) && linkList.length ? ' | ' : ''}
                        {linkList.length
                            ? `${linkList.length}${translation.LINKS} (${linkChaNum}${translation.FILE_CHARS})`
                            : ''}
                        {(fileNum || textChaNum || linkList.length) && videoList.length ? ' | ' : ''}
                        {videoList.length
                            ? `${videoList.length}${translation.VIDEOS} (${videoChaNum}${translation.FILE_CHARS})`
                            : ''}
                    </p>
                    <Input
                        id="chatbotName"
                        addonBefore={translation.AI_ASSISTANT_NAME}
                        value={botName}
                        onChange={handleNameChange}
                        size="large"
                        disabled={creating}
                        autoComplete="off"
                    />
                    <p />
                    {user.role === 'admin' ? (
                        <>
                            <p>{translation.ALLOCATED_USERS}</p>
                            <Select
                                mode="multiple"
                                value={selectedItems}
                                onChange={setSelectedItems}
                                style={{
                                    width: '100%',
                                }}
                                options={filteredUsersOptions.map((item) => ({
                                    value: item,
                                    label: item,
                                }))}
                            />
                            <p />
                        </>
                    ) : null}
                    <Button
                        type="primary"
                        size="large"
                        className="width-100 normal-component"
                        style={{ height: 'auto' }}
                        onClick={handleCreate}
                        loading={creating}>
                        {creating ? translation.CREATE_LOADING : translation.CREATE_AI_ASSISTANT}
                    </Button>
                </div>
            </div>
        </>
    );
}
